.sx-action-button {
  &.inverse {
    .mat-mdc-button {
      color: $primary;
      background-color: $white;

      .mat-icon {
        color: $primary;
      }
    }
  }

  &.mat-mdc-icon-button {
    .mat-mdc-button-touch-target {
      width: 100%;
      height: 100%;
    }
  }

  .mat-mdc-button {
    text-transform: uppercase;
    font-weight: normal;
    padding: 0 $gap 0 0;
    text-decoration: none;

    .mat-icon {
      margin-right: 0;
    }
  }

  &.small {
    .mat-mdc-button {
      font-size: 0.7rem;
      color: $primary;
      line-height: 24px;
      text-transform: none;

      .mat-icon {
        font-size: 14px;
        width: 14px;
        height: 14px;
      }
    }
  }
}
