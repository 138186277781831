.splitter {
  display: flex;
  &__container {
    flex-basis: 100px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: $gap;
    &:last-child {
      margin-right: 0;
    }
  }
}
