.portal-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 5px 0;
  overflow: hidden;

  @include phone {
    flex-direction: column;
  }

  &__item {
    overflow: hidden;
    // width: 15%;
    flex-grow: 1;
    flex-shrink: 1;
    padding-left: $gap;
    padding-right: $gap;
    @include phone {
      padding-left: $gap;
      padding-right: $gap;
    }
    &--buttons {
      display: flex;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      white-space: nowrap;
      @include phone {
        text-align: right;
      }

      .mdc-button {
        margin-right: 10px;
      }
      .mdc-button:last-child {
        margin-right: 0;
      }

      .mat-icon {
        margin: 0 !important;
      }
    }
    .mat-mdc-form-field {
      padding-left: $gap;
      padding-right: $gap;
      width: 100%;
      @include phone {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &--top-padding {
    padding-top: $gap;
  }
}
