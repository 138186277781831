.section-title {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
  margin: 50px 16px 16px 16px;
  &--small-top-margin {
    margin-top: 16px;
  }
  &--no-horizontal-margin {
    margin-left: 0;
    margin-right: 0;
  }
}
