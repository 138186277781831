$gap: 16px;
$half-gap: 8px;
$quarter-gap: 8px;
$double-gap: 32px;

// Custom colors
$primary: #811330;
$error: #e2354a;
$form-error: #f44336;
$success: #3babef;
$white: #fff;
$text-light: #989898;
$light-grey: #989898;
$link-hover: #f7f7f7;
