.key-value {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  &--top-aligned {
    .key-value__key {
      align-self: flex-start;
    }
    .key-value__value {
      align-self: flex-start;
    }
  }

  &__key {
    flex-basis: 120px;
    margin-right: $half-gap;
    padding: $quarter-gap $gap;
    color: $text-light;
    font-weight: 500;
    font-size: 0.7rem;
    text-transform: uppercase;
    text-align: left;
  }
  &__value {
    flex-basis: 0;
    flex-grow: 1;
    margin-left: $half-gap;
    padding: $half-gap $gap;
    font-size: 1rem;
    font-weight: lighter;
    @extend .white-space--pre-wrap;
  }

  &--detail-card-header {
    .key-value__key {
      padding: 0;
      padding-right: $gap;
      flex-basis: 160px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.25px;
      color: #ffffff;
      opacity: 1;
      text-transform: none;
    }
    .key-value__value {
      padding: 0 $gap;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.25px;
    }
  }

  @include phone {
    margin-bottom: 1rem;

    &__value {
      flex-basis: 100%;
    }
  }
}
