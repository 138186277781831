.margin {
  &-top {
    margin-top: $gap;
    &--double {
      margin-top: $double-gap;
    }
  }
  &-bottom {
    margin-bottom: $gap;
    &--double {
      margin-bottom: $double-gap;
    }
  }
  &-right {
    margin-right: $gap;
    &-half {
      margin-right: $half-gap;
    }
  }
}
