.portal-table {
  width: calc(100% - 10px);
  margin: 0 5px;
  overflow: auto;

  &__table {
    width: 100%;
  }

  &__loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__data-row {
    cursor: pointer;

    &--selected {
      background-color: $primary !important;

      td {
        color: #fff;
      }
    }
  }

  &__header-cell {
    color: $primary;

    .mat-sort-header-button {
      text-align: left;
    }
  }

  &__header-cell.mat-mdc-header-cell,
  &__data-cell.mat-mdc-cell {
    vertical-align: middle;
    width: 25%;
    padding: 0 10px;
  }

  &__footer {
    display: flex;
    &-aside {
      flex: 0 0 auto;
      margin: $gap;
    }
    &-spacer {
      flex: 1 1 auto;
    }
    &-paginator {
      flex: 0 0 auto;
    }
  }

  &--responsive {
    .portal-table__header-row {
      @include phone {
        display: none;
      }
    }

    .portal-table__mobile-label {
      display: none;
      @include phone {
        display: block;
      }
    }

    .mat-mdc-table .mat-mdc-row {
      @include phone {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        border-bottom: 2px solid #e2e2e2;
        padding: $gap $half-gap;
        .mat-mdc-cell {
          padding: 4px;
          height: auto;
          display: flex;
          width: 100%;
          border: none;
          .portal-table__mobile-label {
            flex-basis: 140px;
            flex-shrink: 0;
            flex-grow: 0;
            font-weight: 500;
            margin-right: $quarter-gap;
          }
          .portal-table__cell-value {
            flex-basis: auto;
            flex-shrink: 1;
            flex-grow: 1;
          }
        }
      }
    }
  }
}
