html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

p {
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 16px;
}

div {
  line-height: 1.3rem;
}

main {
  position: relative;
}

.img-responsive {
  width: auto;
  max-width: 100%;
}

.mat-toolbar {
  background: none;

  .brand {
    margin: 10px;
    max-width: 250px;
    @include phone() {
      margin-left: auto;
      margin-right: auto;
    }
    @include tablet() {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.mat-secondary {
  background-color: #fff !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 24px;
  height: 24px;
  padding: 0;
}

.mat-mdc-button.mdc-button > .mat-icon {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: transparent !important;
}

.ng-fa-icon {
  margin-right: 5px;
}

.sidenav-container {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;

  .brand {
    margin: 32px;
    max-width: 250px;
  }

  .mat-sidenav {
    border-right: none;
  }

  .mat-sidenav-content {
    padding: 32px;
  }

  @include phone {
    top: 56px;

    .mat-sidenav {
      .brand {
        display: none;
      }
    }

    .mat-sidenav-content {
      padding: 10px;
    }
  }

  @include tablet {
    top: 56px;

    .mat-sidenav {
      .brand {
        display: none;
      }
    }
  }
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: $primary !important;
}
.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  border-color: $primary !important;
  background-color: $primary !important;
}

.menu-button {
  margin-right: $gap !important;
}

.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    color: $white !important;
    background-color: $success !important;
  }

  &.error {
    .mdc-snackbar__surface {
      background-color: $error !important;
    }
  }
}

.mat-mdc-form-field-subscript-wrapper {
  font-size: 75%;
}

/* avoid auto zoom on mobile browsers */
.mat-mdc-input-element {
  font-size: 16px !important;
}
