.nav {
  // Not nice BEM but we need to override mat-button so the styles match
  &__item {
    mat-icon {
      margin-right: 8px;
    }

    &.mat-mdc-button {
      font-size: 1rem;
      font-weight: lighter;
      width: 100%;
      text-align: left;
      text-decoration: none;
      justify-content: start;
    }
    &--small.mat-mdc-button {
      font-size: 12px;
    }
    &.active {
      background: #f8f8f8;
    }
  }
  // Not nice BEM but we need to override mat-button so the styles match
  &__divider.mat-divider {
    margin: 15px 32px;
  }
  &__context-title {
    font-size: 22px;
    margin-left: 34px;
    margin-bottom: 40px;
    display: flex;
    max-width: 250px;
  }
  &__context-title-icon {
    flex-basis: 34px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
  }
  &__context-title-text {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
