.loading-spinner {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  &--primary span {
    border: 3px solid $primary;
    border-color: $primary transparent transparent transparent;
  }

  &--white span {
    border: 3px solid $white;
    border-color: $white transparent transparent transparent;
  }

  & span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 50%;
    animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  & span:nth-child(1) {
    animation-delay: -0.45s;
  }

  & span:nth-child(2) {
    animation-delay: -0.3s;
  }

  & span:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
