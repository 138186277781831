body .mat-mdc-dialog-container {
  padding: 0;
  overflow-x: hidden;
}

.dialog {
  @include desktop {
    min-width: 600px;
    display: block;
  }
  @include tablet {
    min-width: 400px;
    display: block;
  }

  &--margin-top {
    margin-top: 10px;
  }

  &__row {
    .mat-mdc-form-field-subscript-wrapper {
      padding: 0 !important;
    }
    .mat-mdc-select-arrow-wrapper {
      padding-top: 10px !important;
    }
    .mat-mdc-form-field-flex {
      padding: 0 0 0 2px !important;
      background-color: $white !important;
    }
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .card-layout.mat-mdc-card {
    box-shadow: none;
    margin: 0;

    .mat-mdc-card-content {
      padding-bottom: 0;
      @include phone {
        padding: 0;
      }
    }

    .mat-horizontal-content-container {
      padding-bottom: 0;
    }
  }

  &__content {
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0 !important;

    &--margin {
      margin: 0 16px;
    }

    &--height {
      height: 435px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &__spacer {
      flex: 1 1 auto;
    }

    &__message {
      font-size: 1rem;
      align-self: center;
    }

    &__left-content {
      &--margin {
        margin-left: -18px;
      }

      &__button {
        padding-left: 27px !important;

        &__text {
          &--red {
            color: $primary;
          }
        }
      }
    }
  }

  &__radio-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
  }

  &__radio-button {
    margin: 5px;
  }

  &__number-input.mat-mdc-form-field {
    width: 80px;
    text-align: right;
  }

  .mat-horizontal-content-container {
    @include phone {
      padding: 0;
    }
  }
}

.mat-mdc-dialog-content {
  padding-top: 10px !important;
  padding-bottom: 20px !important;

  .mat-mdc-form-field.stretch {
    width: 100%;
  }
}

.mat-mdc-dialog-actions {
  text-align: right;
}

body .cdk-overlay-pane {
  @include phone {
    max-width: 100% !important;
  }
}

.loading-dialog-panel {
  .mat-mdc-dialog-container {
    overflow: hidden;

    .mat-mdc-dialog-surface {
      padding: $double-gap;
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.loading-dialog {
  &__message {
    margin-top: $gap;
    font-weight: 500;
    text-align: center;
  }
}
