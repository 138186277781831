.mat-mdc-card {
  margin-bottom: 50px;
  padding: 16px;

  .mat-mdc-card-header {
    .mat-mdc-card-title {
      font-weight: lighter;
      font-size: 1.5rem;

      > span {
        vertical-align: text-top;
      }

      .mat-icon {
        margin-right: 8px;
      }
    }

    .mat-mdc-card-subtitle {
      margin-top: 0 !important;
      font-size: 1rem;
      line-height: 1.3rem;
      font-weight: lighter;
    }
  }

  .mat-mdc-card-content {
    padding: 30px 0;

    .mat-divider {
      position: relative;
      width: auto;
      margin: 0 10px 15px;
    }

    .mat-mdc-card-actions,
    .mdc-card__actions {
      align-items: start;
      min-height: unset;
    }
  }

  @include phone {
    &.no-border-on-mobile {
      box-shadow: none !important;
    }
  }
}
