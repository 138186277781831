.card-layout {
  @extend .no-border-on-mobile;

  @include phone() {
    &.mat-mdc-card {
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  max-width: 1024px;

  &--no-content-padding {
    .mat-mdc-card .mat-mdc-card-content.card-layout__content {
      padding-top: 0;
    }
  }

  .mat-mdc-card-header {
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  }

  &__subtitle,
  & .mat-mdc-card-subtitle {
    color: rgba(0, 0, 0, 0.54);
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 16px;
    margin-bottom: $gap;
    margin-top: 0px;
  }

  &__content {
    position: relative;
  }

  &__buttons {
    position: absolute;
    right: 0px;
    top: -20px;

    button {
      margin-left: $gap;
    }
  }
}
