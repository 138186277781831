.mat-accordion .mat-expansion-panel {
  .mat-expansion-panel-header {
    line-height: 1.2rem;

    .mat-content {
      flex-wrap: wrap;
    }
  }
}

/* Fix: Avoid showing accordion as expanded on page transition animation */
.ng-animating .mat-accordion .mat-expansion-panel {
  .mat-expansion-panel-header {
    height: 48px;
  }
  .mat-expansion-panel-content {
    height: 0px;
    visibility: hidden;
  }
}
/* End Fix */

@include phone {
  .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding: 10px 0;
  }
}
