.sx-action-button {
  &.inverse {
    .mat-mdc-button {
      color: $primary;
      background-color: $white;

      .mat-icon {
        color: $primary;
      }
    }
  }

  .mat-mdc-button {
    text-transform: uppercase;
    font-weight: normal;
    padding: 0 $gap 0 0;
    text-decoration: none;

    .mdc-button__label {
      display: flex;
      align-items: center;
      text-align: left;
    }
  }

  .mat-mdc-button:disabled {
    color: lightgray;
  }

  &.small {
    .mat-mdc-button {
      font-size: 0.7rem;
      color: $primary;
      line-height: 24px;
      text-transform: none;

      .mat-icon {
        font-size: 14px;
        width: 14px;
        height: 14px;
      }
    }
  }
}
