.ngx-file-drop {
  &__drop-zone {
    border-radius: 0 !important;
    height: 60px !important;
    border-color: $primary !important;
    color: $primary !important;

    &--error {
      border: dotted 2px $form-error !important;

      .ngx-file-drop__drop-zone-label {
        color: $form-error !important;
      }
    }
  }
  &__content {
    height: 60px !important;
    color: $primary !important;
  }
  &__drop-zone-label {
    display: inline-block;
    margin-right: $gap;
  }
}
