.exam-module {
  display: block;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  &__section {
    border-bottom: solid 1px $light-grey;
    padding-bottom: 10px;
    margin-bottom: 30px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  &__content {
    margin-top: 15px;
  }

  a {
    color: $primary;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .mat-chip-list-wrapper {
      margin: 0;

      .mat-mdc-standard-chip.mat-mdc-chip {
        margin: 0;
      }
    }

    .mat-mdc-chip {
      background: none;
      border-radius: 11px;
      font-weight: 300;
      min-height: 22px;
      border: solid 1px $primary;
      color: $primary;
    }
  }

  &__options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &__title {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: start;

    > .sx-action-button {
      .mat-button-wrapper {
        color: grey;
      }
    }
  }

  /*
  address {
    white-space: pre-line;
  }
*/

  @include desktop {
    .module-info__left {
      margin-right: 100px;
    }
  }

  @include tablet {
    .module-info__left {
      margin-right: 100px;
    }
  }
}
