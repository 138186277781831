.detail-card {
  &.mat-mdc-card {
    border: solid 1px $primary;
    padding: 0;
    margin-bottom: 0;

    .mat-mdc-card-header,
    .mat-mdc-card-content {
      padding: 16px;
      font-size: 14px;
      letter-spacing: 0.25px;

      div {
        font-size: 14px;
        letter-spacing: 0.25px;
      }
    }

    .mat-mdc-card-header {
      background-color: $primary;

      .mat-card-header-text {
        margin: 0;
        width: 100%;
      }

      .mat-mdc-card-title,
      .mat-mdc-card-subtitle,
      .mat-mdc-button,
      .mat-mdc-input-element {
        color: $white;
      }

      .inverse {
        .mat-mdc-button {
          color: $primary;
        }
      }
    }
  }

  &__title {
    padding-bottom: 7px !important;
  }

  &__info-list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  &__exam-result-info {
    margin-top: 10px;
    display: flex;
  }

  &__info-item {
    display: flex;
    margin-right: 40px;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 400;
    align-items: flex-start;
    @include phone() {
      margin-bottom: 4px;
    }
    > .mat-icon {
      color: $white;
      margin-right: 5px;
      align-self: flex-start;
    }
  }
  &__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    margin-top: 20px;
    .mat-icon {
      color: $white;
    }

    .sx-action-button {
      margin-right: 40px;
    }
  }
  .mat-mdc-tab-group .mat-tab-label-active {
    color: $primary;
  }
}
