.display {
  &-inline {
    display: inline;
  }
  &-inline-block {
    display: inline-block;
  }

  &-text {
    &--underline {
      text-decoration: underline;
    }

    &--pb-3 {
      padding-bottom: 3px;
    }
  }
}
