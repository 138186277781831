@import '../../styles/config/variables';

.sx-copy-to-clipboard {
  display: flex;
  gap: 6px;
  align-items: stretch;

  &__input {
    border-radius: 0.5rem;
    border: solid 1px $white !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
    font-size: 0.8rem !important;
    padding: 0 0.8rem !important;
    min-width: 90px;
    width: 100%;
  }

  &__button {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;

    .mat-icon {
      color: $white;
    }
  }
}
