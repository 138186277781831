.mat-expansion-panel {
  width: 100%;

  .mat-expansion-panel-content {
    font-size: inherit;
    align-items: start;
  }

  .mat-expansion-panel-body {
    width: 100%;
  }

  &--frameless {
    border: none;
    box-shadow: none !important;
    .mat-expansion-panel-body {
      padding: 0;
      width: 100%;
    }
  }

  &__nav-item {
    padding: 0 14px;
    margin: 6px 0 !important;
    user-select: none;

    .mat-icon {
      padding-right: 4px;
    }

    .mat-expansion-header-title {
      padding-right: 5px;
    }

    .mat-mdc-button {
      padding-left: 21px;
      font-size: 1rem;
      font-weight: lighter;
      width: 100%;
      text-align: left;
    }

    .mat-expansion-panel-header {
      line-height: 1.7rem !important;
      padding-left: 17px !important;
      height: 40px !important;
    }

    .mat-expansion-panel-header-title {
      font-size: 1rem;
      font-weight: lighter;
    }

    .mat-accordion-list-icon {
      padding-bottom: 4px;
    }

    @media (max-width: 599px) {
      .mat-expansion-panel-header {
        padding: 0 !important;
      }

      .mat-mdc-button {
        padding-left: 0 !important;
      }
    }
  }
}

@media (max-width: 599px) {
  .mat-expansion-panel__nav-item {
    padding: 0 31px !important;
  }
}
