@include phone() {
  // Stepper
  .mat-vertical-stepper-header.mat-step-header {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  // Dialog
  .mat-mdc-card-content .mat-mdc-dialog-actions {
    padding: 0;
  }
}
