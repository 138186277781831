.public-card {
  @extend .no-border-on-mobile;

  @include phone {
    box-shadow: none;
    padding: 0;
  }

  &__content.mat-mdc-card-content {
    padding-bottom: 0 !important;
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
  }

  &__actions.mat-mdc-card-actions {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}
