.expansion-list {
  .mat-expansion-panel {
    @include phone() {
      .mat-content {
        flex-direction: column;
        > .mat-expansion-panel-header-title,
        > .mat-expansion-panel-header-description {
          flex-basis: auto;
          flex-grow: 1;
        }
      }
    }
    display: block;
    box-shadow: none;
    &.cancelled {
      .mat-expansion-panel-header {
        text-decoration: line-through;
      }
    }

    .mat-expansion-panel-header {
      border-bottom: solid 1px rgba(0, 0, 0, 0.12);
      border-radius: unset;

      &.mat-expanded {
        .mat-expansion-panel-header-title,
        .mat-expansion-panel-header-description {
          color: $primary;
        }
      }
    }

    .mat-expansion-panel-header-title {
      flex-basis: 0;
      flex-grow: 2;
    }

    .mat-expansion-panel-header-description {
      flex-basis: 0;
      justify-content: space-between;
      align-items: center;
    }

    .mat-expansion-indicator::after {
      color: $primary;
    }

    .mat-expansion-panel-body {
      margin-top: 10px;
      padding: 0;
    }
  }
}
