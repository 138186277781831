.module-info {
  display: flex;
  flex-wrap: wrap;

  @include phone {
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__left,
  &__right {
    margin-bottom: 20px;
    flex-basis: 100px;
    flex-grow: 1;
    flex-shrink: 1;
    @include phone {
      margin-bottom: 0px;
      flex-basis: 10px;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    > .mat-icon {
      color: $primary;
      margin-right: 5px;
      align-self: flex-start;
    }

    &__label {
      flex-basis: 120px;
      margin-right: $half-gap;
      color: $text-light;
      font-weight: 500;
      font-size: 0.7rem;
      text-transform: uppercase;
      text-align: left;
    }

    &__text {
      font-size: 15px;

      &--bold {
        font-weight: 500;
      }

      &--copyable {
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
