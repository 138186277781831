.public-frame {
  display: block;
  max-width: 524px;
  width: auto;
  margin: 0 auto;

  &__brand {
    max-width: 380px;
    margin: 0 auto 50px;
  }
  &__language-switch {
    display: flex;
    justify-content: flex-end;
  }
}
