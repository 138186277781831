body .mat-stepper-horizontal,
body .mat-stepper-vertical {
  @include phone {
    margin-left: -$gap;
    margin-right: -$gap;
    .mat-mdc-dialog-content {
      padding: 0;
      margin: 0 -16px;
    }
  }
}
